

  .background-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url('./assets/Topographic.png');
    background-size: cover;
    background-repeat: repeat-y; /* Repeat vertically */
    background-attachment: scroll;
    z-index: 1; /* Ensure it's under the content */
    mix-blend-mode: soft-light;
    overflow-x: hidden;
  }
  
  .content {
    position: relative;
    z-index: 2; /* Above the background and overlay */
    color: #000; /* Adjust text color for readability */
    padding: 20px;
    background-color: transparent; 
    mix-blend-mode: normal;
    /* Ensure content is visually above the overlay and background */
  }

  .container {
    width: 100vw;
  }
  